import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import minusIcon from '/media/icons/minus-icon.svg'; // Adjust the path as needed
import plusIcon from '/media/icons/plus_icon.svg'; // Adjust the path as needed
import heartIcon from '/media/icons/heart.svg'; // Import the heart icon
import fitAssistantImage from '/media/size-assistant.png'; // Import the PNG file
import errorImage from '/media/404.png';
import '../css/productpage.css';
import { CartContext } from '../utils/CartContext';
import { UserContext } from '../utils/UserContext';
import { addToFavorites } from '../utils/MainFunctionality';


const ProductInfo = ({ item }) => {
  // Add a fallback for sizes in case it's undefined
  const sizes = item.sizes || [];
  // console.log(sizes);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSize, setSelectedSize] = useState('');
  const [quantity, setQuantity] = useState(1);
  const [selectedColor, setSelectedColor] = useState(null);
  const [mainImage, setMainImage] = useState(item.imageUrl || errorImage);
  const [thumbnails, setThumbnails] = useState(item.thumbnails && item.thumbnails.length > 0 ? item.thumbnails : [mainImage]);
  const { addToCart } = useContext(CartContext);
  const { user } = useContext(UserContext);

  useEffect(() => {
    if (selectedColor) {
      const colorImages = item.colors.find(color => color.name === selectedColor)?.images || [];
      if (colorImages.length > 0) {
        setMainImage(colorImages[0] || errorImage);
        setThumbnails(colorImages.length > 0 ? colorImages : [mainImage]);
      } else {
        setMainImage(item.imageUrl || errorImage);
        setThumbnails(item.thumbnails && item.thumbnails.length > 0 ? item.thumbnails : [mainImage]);
      }
    }
  }, [selectedColor, item.imageUrl, item.thumbnails, item.colors, mainImage]);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleSizeClick = (size) => {
    setSelectedSize(size);
  };
  const handleColorClick = (colorName) => {
    const selectedColorImages = item.colors.find(color => color.name === colorName)?.images || [];
    
    if (selectedColorImages.length > 0) {
      setSelectedColor(colorName);
      setMainImage(selectedColorImages[0]); // Assuming you want to set the first image as the main image
    } else {
      setSelectedColor(colorName);
    }
  };

  const handleQuantityChange = (amount) => {
    setQuantity((prevQuantity) => Math.max(1, prevQuantity + amount));
  };

  const handleAddToCart = () => {
    if (!selectedSize || !selectedColor) {
      alert("Please select a size and color.");
      return;
    }
  
    const cartItem = {
      specific_id: `${item.id}-${selectedColor}-${selectedSize}`,
      id: item.id,
      name: item.name,
      color: selectedColor,
      size: selectedSize,
      quantity,
      price: item.price,
      image: mainImage,  // Add main image to show in the cart
    };
    addToCart(cartItem);
  };

  const handleAddToFavorites = async () => {
    if (!user) {
      alert('Please sign in to add items to favorites.');
      return;
    }

    const response = await addToFavorites(user.id, item.id);
    if (response) {
      alert('Item added to favorites successfully.');
    } else {
      alert('Failed to add item to favorites.');
    }
  };
  
  return (
    <div className="content-container">
    <div className="product-image-gallery">
        <img src={mainImage} alt="Main Product" className="main-image" />
        <div className="thumbnails">
          {thumbnails.map((image, index) => (
            <img 
              key={index} 
              src={image} 
              alt={`Thumbnail ${index + 1}`} 
              onClick={() => setMainImage(image)} 
              className="thumbnail"
            />
          ))}
        </div>
      </div>
      <div className="product-info-container">
      <div className="product-details">
        <h1>{item.name}</h1>
        <p className="price">€{item.price}</p>
        <p className="description">{item.description}</p>
      </div>
      <div className="fit-assistant">
        <span className="fit-assistant-link" onClick={toggleModal}>Fit Assistant</span>
        <hr className="fit-separator" />
      </div>
      {item.colors && item.colors.length > 0 && (
      <div className="color-selection">
        <label>Select Color:</label>
        <div className="color-options">
          {item.colors.map((color) => (
            <span
              key={color.name}
              className={`color-option ${selectedColor === color.name ? 'selected' : ''}`}
              onClick={() => handleColorClick(color.name)}
            >
              {color.name}
            </span>
          ))}
        </div>
      </div>
    )}
      <div className="size-quantity">
        <label>Select Size:</label>
        <div className="size-options">
          {sizes.length > 0 ? sizes.map((size) => (
            <span
              key={size}
              className={`size-option ${selectedSize === size ? 'selected' : ''}`}
              onClick={() => handleSizeClick(size)}
            >
              {size}
            </span>
          )) : <span>No sizes available</span>}
        </div>
        <div className="quantity-cart-favourites pt-4">
          <div className="quantity-control">
            <button onClick={() => handleQuantityChange(-1)}>
              <img src={minusIcon} alt="Decrease quantity" />
            </button>
            <span>{quantity}</span>
            <button onClick={() => handleQuantityChange(1)}>
              <img src={plusIcon} alt="Increase quantity" />
            </button>
          </div>
          <div className="add-to-cart">
            <button onClick={handleAddToCart}>Add to Cart</button>
          </div>
          <div className="add-to-favourites">
            <button onClick={handleAddToFavorites}>
              <img src={heartIcon} alt="Add to Favourites" />
            </button>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={toggleModal}>&times;</span>
            <img src={fitAssistantImage} alt="Fit Assistant" />
          </div>
        </div>
      )}
    </div>
    </div>
  );
};

ProductInfo.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired,
    imageUrl: PropTypes.string, // Main image URL
    thumbnails: PropTypes.arrayOf(PropTypes.string), // Array of thumbnail URLs
    colors: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      images: PropTypes.arrayOf(PropTypes.string).isRequired,
    })).isRequired,
    sizes: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
};

export default ProductInfo;