import React, { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import '../css/paymentsuccess.css'; // Import your CSS file for styling
import { CartContext } from '../utils/CartContext';
import { UserContext } from '../utils/UserContext'; // Import UserContext
import { sendOrderDetailsToDB, prepareOrderDetails } from '../utils/MainFunctionality';
import { useNavigate } from 'react-router-dom';


const PaymentSuccessfulPage = () => {
    const { clearCart, cartItems } = useContext(CartContext);
    const { user } = useContext(UserContext); // Use user context
    const navigate = useNavigate();

    useEffect(() => {
        // Clear the cart when the component mounts
        clearCart();
    
        // Prepare order details
        // const orderDetails = prepareOrderDetails(user, cartItems);
    
        // Send order details to database
    //    sendOrderDetailsToDB(orderDetails);

             // Redirect to the home page after a delay
             const timer = setTimeout(() => {
                navigate('/');
            }, 3000);
      
          // Cleanup the timer on component unmount
          return () => clearTimeout(timer);

      }, []); //clearCart, cartItems, user, navigate

    return (
        <div className="payment-successful-container">
        <h1>Payment Successful</h1>
        <p>Thank you for your purchase! Your payment has been successfully processed.</p>
        <Link to="/" className="btn btn-primary">Return to Home</Link>
        </div>
    );
};

export default PaymentSuccessfulPage;