import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import '../css/registerpopup.css';
import { UserContext } from '../utils/UserContext';
import { registerNewUser, signInUser, resetPassword } from '../utils/MainFunctionality'; // Import the resetPassword function
import '@fortawesome/fontawesome-free/css/all.min.css';

const RegisterPopup = ({ onClose, isRegisterMode: initialMode }) => {
  const [isRegisterMode, setIsRegisterMode] = useState(initialMode);
  const { setUser } = useContext(UserContext);
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState(''); // State for new password
  const [userExists, setUserExists] = useState(false); // State to manage user exists popup
  const [errors, setErrors] = useState({}); // State to manage validation errors
  const [isResetPasswordMode, setIsResetPasswordMode] = useState(false); // State to manage reset password mode
  const [confirmPassword, setConfirmPassword] = useState(''); // State for confirm password

  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    setIsRegisterMode(initialMode);
  }, [initialMode]);

  const toggleMode = () => {
    setIsRegisterMode(!isRegisterMode);
    setIsResetPasswordMode(false); // Reset reset password mode
    resetForm();
  };

  const toggleResetPasswordMode = () => {
    setIsResetPasswordMode(!isResetPasswordMode);
    resetForm();
  };

  const resetForm = () => {
    setName('');
    setSurname('');
    setPhone('');
    setEmail('');
    setPassword('');
    setConfirmPassword('');
    setNewPassword('');
    setErrors({});
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*\d{3,})(?=.*[a-zA-Z].*[a-zA-Z].*[a-zA-Z].*[a-zA-Z]).{8,}$/;
    return passwordRegex.test(password);
  };

  const validatePhone = (phone) => {
    const phoneRegex = /^\d{8}$/;
    return phone === '' || phoneRegex.test(phone);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = {};
    if (isRegisterMode) {
      if (!name) newErrors.name = 'Name is required.';
      if (!surname) newErrors.surname = 'Surname is required.';
      if (!email) newErrors.email = 'Email is required.';
      if (!password) newErrors.password = 'Password is required.';
      if (email && !validateEmail(email)) newErrors.email = 'Please enter a valid email address.';
      if (password && !validatePassword(password)) newErrors.password = 'Password must contain at least 4 letters, 3 numbers, one capital character, and one special character.';
      if (phone && !validatePhone(phone)) newErrors.phone = 'Phone number must be exactly 8 digits.';
      if (isRegisterMode && password !== confirmPassword) newErrors.confirmPassword = 'Passwords do not match.';
      if (isResetPasswordMode && newPassword !== confirmPassword) newErrors.confirmPassword = 'Passwords do not match.';
      
      setErrors(newErrors);
      if (Object.keys(newErrors).length > 0) return;

      try {
        const newUser = await registerNewUser(name, surname, email, password, phone, 9); // group 9 for new users
        if (newUser === null) {
          setUserExists(true); // Show user exists popup
        } else {
          setUser(newUser); // Set the user context after registration
          onClose(); // Close the popup
        }
      } catch (error) {
        console.error('Error registering new user:', error);
      }
    } else if (isResetPasswordMode) {
      if (!email) newErrors.email = 'Email is required.';
      if (!password) newErrors.password = 'Old password is required.';
      if (!newPassword) newErrors.newPassword = 'New password is required.';
      if (email && !validateEmail(email)) newErrors.email = 'Please enter a valid email address.';
      if (newPassword && !validatePassword(newPassword)) newErrors.newPassword = 'New password must contain at least 4 letters, 3 numbers, one capital character, and one special character.';
      if (isRegisterMode && password !== confirmPassword) newErrors.confirmPassword = 'Passwords do not match.';

      setErrors(newErrors);
      if (Object.keys(newErrors).length > 0) return;

      try {
        const response = await resetPassword(email, password, newPassword);
        if (response === null) {
          setErrors({ resetPassword: 'Invalid email or old password. Please try again.' });
        } else {
          alert('Password reset successfully. Please sign in with your new password.');
          setIsResetPasswordMode(false);
        }
      } catch (error) {
        console.error('Error resetting password:', error);
      }
    } else {
      try {
        const user = await signInUser(email, password);
        if (user === null) {
          setErrors({ signIn: 'Invalid email or password. Please try again.' });
        } else {
          setUser(user); // Set the user context after login
          onClose(); // Close the popup
        }
      } catch (error) {
        console.error('Error signing in user:', error);
      }
    }
  };

  const handleUserExistsClose = () => {
    setUserExists(false);
    setIsRegisterMode(false); // Switch to sign-in mode
  };

  return (
    <div className="register-popup">
      <div className="register-popup-content">
        <img 
          src="/media/icons/close_black.svg" 
          alt="Close" 
          onClick={onClose} 
          className="close-btn" 
          style={{ cursor: 'pointer', width: '16px', height: '16px' }} 
        />
        <h2>{isRegisterMode ? 'REGISTER' : isResetPasswordMode ? 'RESET PASSWORD' : 'SIGN IN'}</h2>
        <form onSubmit={handleSubmit}>
          {isRegisterMode && (
            <>
              <div className="form-group">
                <input 
                  type="text" 
                  placeholder="Name*" 
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
                {errors.name && <p className="error-message">{errors.name}</p>}
              </div>
              <div className="form-group">
                <input 
                  type="text" 
                  placeholder="Surname*" 
                  value={surname}
                  onChange={(e) => setSurname(e.target.value)}
                  required
                />
                {errors.surname && <p className="error-message">{errors.surname}</p>}
              </div>
              <div className="form-group">
                <input 
                  type="text" 
                  placeholder="Phone" 
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
                {errors.phone && <p className="error-message">{errors.phone}</p>}
              </div>
              <div className="form-group">
                <input 
                  type="email" 
                  placeholder="Email*" 
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                {errors.email && <p className="error-message">{errors.email}</p>}
              </div>
            </>
          )}
          {!isRegisterMode && !isResetPasswordMode && (
            <div className="form-group">
              <input 
                type="email" 
                placeholder="Email*" 
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              {errors.email && <p className="error-message">{errors.email}</p>}
            </div>
          )}
          {isResetPasswordMode && (
            <>
              <div className="form-group">
                <input 
                  type="email" 
                  placeholder="Email*" 
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                {errors.email && <p className="error-message">{errors.email}</p>}
              </div>
              <div className="form-group">
                <div className="password-input-container">
                  <input 
                    type={showPassword ? "text" : "password"} 
                    placeholder="Old Password*" 
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <i 
                    className={`fas ${showPassword ? 'fa-eye' : 'fa-eye-slash'} password-toggle-icon`} 
                    onClick={() => setShowPassword(!showPassword)}
                  ></i>
                </div>
                {errors.password && <p className="error-message">{errors.password}</p>}
              </div>
              <div className="form-group">
                <div className="password-input-container">
                  <input 
                    type={showPassword ? "text" : "password"} 
                    placeholder="New Password*" 
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    required
                  />
                  <i 
                      className={`fas ${showPassword ? 'fa-eye' : 'fa-eye-slash'} password-toggle-icon`} 
                    onClick={() => setShowPassword(!showPassword)}
                  ></i>
                </div>
                {errors.newPassword && <p className="error-message">{errors.newPassword}</p>}
              </div>
              <div className="form-group">
                <div className="password-input-container">
                  <input 
                    type={showConfirmPassword ? "text" : "password"} 
                    placeholder="Confirm New Password*" 
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                  />
                  <i 
                    className={`fas ${showPassword ? 'fa-eye' : 'fa-eye-slash'} password-toggle-icon`} 
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  ></i>
                </div>
                {errors.confirmPassword && <p className="error-message">{errors.confirmPassword}</p>}
              </div>
            </>
          )}
          {!isResetPasswordMode && (
            <div className="form-group">
              <div className="password-input-container">
                <input 
                  type={showPassword ? "text" : "password"} 
                  placeholder="Password*" 
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <i 
                  className={`fas ${showPassword ? 'fa-eye' : 'fa-eye-slash'} password-toggle-icon`} 
                  onClick={() => setShowPassword(!showPassword)}
                ></i>
              </div>
              {errors.password && <p className="error-message">{errors.password}</p>}
            </div>
          )}
          {isRegisterMode && (
            <div className="form-group">
              <div className="password-input-container">
                <input 
                  type={showConfirmPassword ? "text" : "password"} 
                  placeholder="Confirm Password*" 
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
                <i 
                  className={`fas ${showPassword ? 'fa-eye' : 'fa-eye-slash'} password-toggle-icon`}  
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                ></i>
              </div>
              {errors.confirmPassword && <p className="error-message">{errors.confirmPassword}</p>}
            </div>
          )}
          <button type="submit">{isRegisterMode ? 'REGISTER' : isResetPasswordMode ? 'RESET PASSWORD' : 'SIGN IN'}</button>
          {errors.signIn && <p className="error-message">{errors.signIn}</p>}
        </form>
        {!isRegisterMode && !isResetPasswordMode && (
          <button onClick={toggleResetPasswordMode} className="toggle-btn">
            Forgot Password?
          </button>
        )}
        <button onClick={toggleMode} className="toggle-btn">
          {isRegisterMode ? 'Already a member? Sign in now!' : 'New here? Register Now!'}
        </button>
      </div>
      {userExists && (
        <div className="user-exists-popup">
          <div className="user-exists-content">
            <p>User already exists. Please sign in instead.</p>
            <button onClick={handleUserExistsClose}>OK</button>
          </div>
        </div>
      )}
    </div>
  );
};

RegisterPopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  isRegisterMode: PropTypes.bool.isRequired,
};

export default RegisterPopup;