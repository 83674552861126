import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { CartContext } from '../utils/CartContext';
import '../css/cartview.css'; // Assuming you have a CSS file for styling

const CartView = ({ show, onClose }) => {
  const { cartItems, removeFromCart } = useContext(CartContext);
  const navigate = useNavigate();

  if (!show) return null;

  const handleCheckout = () => {
    navigate('/Checkout');
  };

  return (
    <div 
      className="cart-dropdown show" 
      onMouseEnter={() => {}} 
      onMouseLeave={onClose}>
      <div className="dropdown-header">
        <div className="header-title">CART</div>
        <img 
          src="/media/icons/close.svg" 
          alt="Close" 
          onClick={onClose} 
          className="close-btn" 
          style={{ cursor: 'pointer', width: '16px', height: '16px' }} 
        />
      </div>
      <div className="dropdown-content">
        {cartItems.length === 0 ? (
          <div className="empty-cart">Cart is empty</div>
        ) : (
          <div className="cart-items">
            {cartItems.map((item, index) => (
              <div key={index} className="cart-item">
                <img src={item.image} alt={item.name} className="cart-item-image" />
                <div className="cart-item-details">
                  <span className="cart-item-name">{item.name}</span>
                  <span className="cart-item-color">Color: {item.color}</span>
                  <span className="cart-item-size">Size: {item.size}</span>
                  <span className="cart-item-quantity">Quantity: {item.quantity}</span>
                  <span className="cart-item-price">Total: €{(item.price * item.quantity).toFixed(2)}</span>
                </div>
                <button className="remove-btn" onClick={() => removeFromCart(item.specific_id)}>
                <i className="fas fa-trash"></i>
              </button>
              </div>
            ))}
          </div>
        )}
      </div>
      {cartItems.length > 0 && (
        <div className="dropdown-footer">
          <button onClick={handleCheckout}>Proceed to Checkout</button>
        </div>
      )}
    </div>
  );
};


CartView.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CartView;