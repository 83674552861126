/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header'; // Import the Header component
import '../css/homepage.css';
import Footer from '../components/Footer';
import { fetchCategories } from '../utils/MainFunctionality';
import PropTypes from 'prop-types';

const HomePage = ({ categories = [] }) => {
  // const [categories, setCategories] = useState([]);
  const navigate = useNavigate();

  // useEffect(() => {
  //   const getCategories = async () => {
  //     try {
  //       const categoriesWithImages = await fetchCategories();
  //       setCategories(categoriesWithImages);
  //     } catch (error) {
  //       console.error('Error fetching categories:', error);
  //     }
  //   }; 
  //   getCategories();
  // }, []);

  const handleCategoryClick = (category) => {
    // Save the category id to localStorage
    localStorage.setItem('selectedCategoryId', category.id);
    navigate(category.link);
  };

  return (
    <div className="homepage">
      {/* <Header /> */}
      <div className='section-container'>
        <div className="home-container">
          {categories.map(category => (
            <div key={category.id} onClick={() => handleCategoryClick(category)} className="category-link">
              <div className="category">
                <img
                  src={category.imageUrl}
                  alt={category.name}
                  className="category-image"
                  // style={{ width: '700px', height: '500px', objectFit: 'cover' }}
                />
                <div className="category-name">{category.name}</div>
                {category.comingSoon && <div className="coming-soon">COMING SOON</div>}
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
};


HomePage.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  })),
};

export default HomePage;