/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import '../css/carousel.css';

const Carousel = ({ images, interval = 3000 }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  
  const nextSlide = () => {
    if (images.length > 1) {
      setActiveIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }
  };

  const prevSlide = () => {
    if (images.length > 1) {
      setActiveIndex((prevIndex) =>
        prevIndex === 0 ? images.length - 1 : prevIndex - 1
      );
    }
  };

  useEffect(() => {
    if (images.length > 1) {
      const autoPlayInterval = setInterval(nextSlide, interval);
      return () => {
        clearInterval(autoPlayInterval);
      };
    }
  }, [interval, images.length]);

  return (
    <div className="carousel">
      {images.length > 1 && (
        <button onClick={prevSlide} className="carousel__btn carousel__btn--prev">
          &lt;
        </button>
      )}
      <img
        src={images[activeIndex]}
        alt={`Slide ${activeIndex}`}
        className="carousel__img"
      />
      {images.length > 1 && (
        <button onClick={nextSlide} className="carousel__btn carousel__btn--next">
          &gt;
        </button>
      )}
    </div>
  );
};

Carousel.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  interval: PropTypes.number,
};

export default Carousel;