import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function PathNavigationWatcher() {
  const navigate = useNavigate(); 

  useEffect(() => {
    const queryString = window.location.href.split('?')[1];
    if (!queryString) {
      //console.log('No query string found.');
      return;
    }
    
    const searchParams = new URLSearchParams(queryString);
    const path = searchParams.get('page');
    const id = searchParams.get('id');
    //console.log('Page:', path);
    
    if (path) {
        if(id)
        {
            sessionStorage.setItem('confirmationId', id);
        }
      navigate(`/${path}`);
    }
  }, [navigate]);
  
  return null;
}


export default PathNavigationWatcher;   