import React, { useEffect, useState } from 'react';
import ProductInfo from '../components/ProductInfo';
import { useLocation, useParams } from 'react-router-dom';
import { fetchProductById } from '../utils/MainFunctionality'; // Import your API function
import '../css/productpage.css';

const ProductPage = () => {
  const location = useLocation();
  const { id } = useParams(); // Get the product ID from the URL
  const [item, setItem] = useState(null); // Initialize item as null
  const [loading, setLoading] = useState(true); // Track loading state
  const [error, setError] = useState(null); // Track error state

  useEffect(() => {
    const loadProduct = async () => {
      try {
        // First, check if the item is available in location state
        let productItem = location.state?.item || null;
        console.debug("PRODUCT ITEM: " + productItem);
        // If not found in state, check localStorage
        if (!productItem) {
          const savedItem = localStorage.getItem('selectedItem');
          console.debug("PRODUCT ITEM: " + savedItem);
          if (savedItem) {
            productItem = JSON.parse(savedItem);
          }
        }

        // If still not found, fetch from the API
        if (!productItem && id) {
          console.debug("fetching ITEM: ");
          productItem = await fetchProductById(id);
        }

        setItem(productItem); // Set the item state
      } catch (err) {
        setError('Failed to load product.'); // Set error state
      } finally {
        setLoading(false); // Set loading state to false after trying to load
      }
    };

    loadProduct(); // Call the loadProduct function
  }, [id, location.state]); // Dependencies: id and location.state

  // Handle loading and error states
  if (loading) {
    return <p>Loading product details...</p>; // Loading message
  }

  if (error) {
    return <p>{error}</p>; // Error message
  }

  // If the item is not available at this point, show a fallback message
  if (!item) {
    return <p>Product not found.</p>;
  }

  return (
    <div>
      <div className="product-page">
        {/* Uncomment this line if you want to include the image gallery */}
        {/* <ProductImageGallery item={item} /> */}
        <ProductInfo item={item} /> {/* Pass the item details */}
      </div>
      {/* Uncomment this section if you want to show product specs */}
      {/* <div className="product-specs">
        <ProductSpecs item={item} />
      </div> */}
    </div>
  );
};


export default ProductPage;
