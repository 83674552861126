import { Link } from 'react-router-dom';
import '../css/categorybox.css'; // Import the CSS file for styling
import PropTypes from 'prop-types';

const CategoryBox = ({ image, title, link, onClick }) => {
  return (
    <div className="category-box" onClick={onClick}>
      <img src={image} alt={title} className="category-image" />
      <div className="category-title">{title}</div>
    </div>
  );
};

CategoryBox.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

export default CategoryBox;