import { BrowserRouter, Routes, Route } from 'react-router-dom';
import React, { useEffect, useState, useMemo } from 'react';
import HomePage from './pages/HomePage';
import SchoolUniformsPage from './pages/SchoolUniformsPage';
import UniformPage from './pages/UniformPage';
import ItemShopPage from './pages/ItemShopPage';
import KidsClothesPage from './pages/KidsClothesPage';
import WorkwearPage from './pages/WorkwearPage';
import PromotionalPage from './pages/PromotionalPage';
import AllProductsPage from './pages/AllProductsPage';
import PathNavigationWatcher from './utils/PathNavigationWatcher';
import NotFound from './pages/NotFoundPage';
import ContactPage from './pages/ContactPage';
import ProductPage from './pages/ProductPage';
import Header from './components/Header';
import Footer from './components/Footer';
import AccountPage from './pages/AccountPage';
import { fetchCategories } from './utils/MainFunctionality';
import { CartProvider } from './utils/CartContext';
import { UserProvider } from './utils/UserContext';
import CheckoutPage from './pages/CheckoutPage';
import PaymentSuccess from './components/PaymentSuccess';
import PaymentSuccessfulPage from './pages/PaymentSuccesfulPage';

function App() {
  const [categories, setCategories] = useState([]);
  const [error, setError] = useState(null); // Track error state
  const [loading, setLoading] = useState(true); // Track loading state
  const [cartItems, setCartItems] = useState([]);

 useEffect(() => {
    const getCategories = async () => {
      try {
        const categoriesWithImages = await fetchCategories();
        setCategories(Array.isArray(categoriesWithImages) ? categoriesWithImages : []);
      } catch (error) {
        console.error('Error fetching categories:', error);
        setError('Failed to fetch categories.');
      } finally {
        setLoading(false); // Stop loading regardless of success or failure
      }
    };

    getCategories();

    const storedCart = JSON.parse(localStorage.getItem('cartItems')) || [];
    setCartItems(storedCart);
  }, []);
  
    // Memoize Header and Footer components to avoid unnecessary re-renders
    const memoizedHeader = useMemo(() => <Header categories={categories} />, [categories]);
    const memoizedFooter = useMemo(() => <Footer categories={categories} />, [categories]);
  
    // Show a loading state or error message if necessary
    if (loading) {
      return <div>Loading...</div>;
    }
  
    if (error) {
      return <div>{error}</div>;
    }

  return (
    <div>
    <UserProvider>
      <CartProvider> 
      <BrowserRouter>
        {memoizedHeader}
        {/* <Header categories={categories} user={user}/> */}
        <PathNavigationWatcher />
        <Routes>
          <Route exact path="/" index element={<HomePage categories={categories}/>} />
          <Route path="/My-Account" element={<AccountPage/>} />
          <Route path="/Checkout" element={<CheckoutPage/>} />
          <Route path="/Payment-successful" element={<PaymentSuccessfulPage/>} />
          {/* <Route path="/Payment-success" component={PaymentSuccess} /> */}
          <Route path="/Daka-School" element={<SchoolUniformsPage />} />
          <Route path="Daka-School/:category" element={<UniformPage />} />
          <Route path="Daka-School/:category/:subcategory" element={<ItemShopPage />} />
          <Route path="/Daka-Kids" element={<KidsClothesPage />} />
          <Route path="/Daka-Workwear" element={<WorkwearPage />} />
          <Route path="/Daka-Promotional" element={<PromotionalPage />} />
          <Route path="/Contact" element={<ContactPage />} /> 
          <Route path="/Daka-School/:category/:subcategory/:id" element={<ProductPage />} /> 
          <Route path="/My-Account/:id" element={<ProductPage />} /> 
          <Route path="/Daka-All-Products" element={<AllProductsPage />}/>
          <Route path="/Daka-All-Products/:id" element={<ProductPage />} /> 
          <Route path="*" element={<NotFound />} />
        </Routes>
        {memoizedFooter}
        {/* <Footer categories={categories}/> */}
      </BrowserRouter>
      </CartProvider> 
      </UserProvider>
    </div>
  );
}

export default App;