import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import '../css/logindropdown.css'; // Assuming you have a CSS file for styling

const LoginDropdown = ({ user, show, onRegisterClick, onClose, onSignOut}) => {
  if (!show) return null;

  return (
      <div 
      className="profile-dropdown show" 
      onMouseEnter={() => {}} 
      onMouseLeave={onClose}>
      <div className="dropdown-header">
      <div className="header-options">
          {user ? (
            <span className="dropdown-option" onClick={() => onSignOut()}>SIGN OUT</span>
          ) : (
            <>
              <span className="dropdown-option" onClick={() => onRegisterClick('signin')}>SIGN IN</span>
              <span className="dropdown-option" onClick={() => onRegisterClick('join')}>JOIN</span>
            </>
          )}
        </div>
        <img 
          src="/media/icons/close.svg" 
          alt="Close" 
          onClick={onClose} 
          className="close-btn" 
          style={{ cursor: 'pointer', width: '16px', height: '16px' }} 
        />
      </div>
      <div className="dropdown-content">
      <div className="dropdown-list">
          <Link to="/My-Account" className="dropdown-item">
            <img src="/media/icons/profile.svg" alt="My Account" className="dropdown-icon" />
            <span>My Account</span>
          </Link>
          <Link to="/My-Account" className="dropdown-item">
            <img src="/media/icons/orders.svg" alt="My Orders" className="dropdown-icon" />
            <span>My Orders</span>
          </Link>
          <Link to="/My-Account?tab=Favourites" className="dropdown-item">
            <img src="/media/icons/favourite.svg" alt="My Orders" className="dropdown-icon" />
            <span>Favourites</span>
          </Link>
          <Link to="/Contact" className="dropdown-item">
            <img src="/media/icons/chat.svg" alt="Contact Preferences" className="dropdown-icon" style={{ color: 'blue' }} />
            <span>Contact Us</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

LoginDropdown.propTypes = {
  show: PropTypes.bool.isRequired,
  onRegisterClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSignOut: PropTypes.func.isRequired,
};

export default LoginDropdown;