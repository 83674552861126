import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { fetchItemCategories, fetchCarouselImgs } from '../utils/MainFunctionality';
import Carousel from '../components/Carousel';
import PromotionalBanner from '../components/PromotionalBanner';
import '../css/storehome.css';

const UniformPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { category } = useParams(); 
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [carouselImages, setCarouselImages] = useState([]);

  // Try to get the ID from location.state, fallback to localStorage, and then params
  const id = location.state?.id || localStorage.getItem('selectedSchoolUniformId');// || category;
  console.debug("id here " + id)
  // Save the ID to localStorage if it's obtained from location.state
  useEffect(() => {
    if (location.state?.id) {
      localStorage.setItem('selectedSchoolUniformId', location.state.id);
    }
  }, [location.state]);

  const subtitle = location.state?.name || category || 'Uniforms';
  const categoryLink = location.state?.link || `/Daka-School/${category}`;

  useEffect(() => {
    if (!id) {
      navigate('/');
    } else {
      fetchCategories(id);
      fetchCarouselImages(id);
    }
  }, [id, navigate]);

  const fetchCategories = async (id) => {
    setLoading(true);
    try {
      const subcategories = await fetchItemCategories(id);
      setCategories(subcategories);
    } catch (error) {
      console.error('Error fetching categories:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchCarouselImages = async (id) => {
    try {
      const imageUrls = await fetchCarouselImgs(id); 
      setCarouselImages(imageUrls);
    } catch (err) {
      console.error('Error while fetching carousel images:', err);
    }
  };

  const handleCategoryClick = (item) => {
    // Save the clicked item's id to localStorage
    localStorage.setItem('selectedCategoryId', item.id);
    localStorage.setItem('selectedSubcategory', item.name);
    navigate(`/Daka-School${categoryLink}${item.link}`, { state: { id: item.id } });
  };

  return (
    <div>
      <Carousel images={carouselImages} />
      <div className="parent-container">
        <h2 className="sub-title">{subtitle}</h2>
      </div>
      <div className="categories">
        {loading ? (
          <p>Loading...</p>
        ) : (
          categories.map((item, index) => (
            <div key={index} className="category-box" onClick={() => handleCategoryClick(item)}>
              <img src={item.imageUrl} alt={item.name} className="category-image" />
              <div className="category-title">{item.name}</div>
            </div>
          ))
        )}
      </div>
      {/* <PromotionalBanner /> */}
    </div>
  );
};

export default UniformPage;
