import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchAllItems } from '../utils/MainFunctionality'; // Import fetchAllItems
import ItemGallery from '../components/ItemGallery';
import PopularProducts from '../components/PopularProducts'; 
import ErrorBoundary from '../utils/ErrorBoundary';

const AllProductsPage = () => {
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchItems();
  }, []);

  const fetchItems = async () => {
    setLoading(true);
    try {
      const fetchedItems = await fetchAllItems(); // Call fetchAllItems
      setItems(fetchedItems);
    } catch (error) {
      console.error('Error fetching items:', error);
      // Handle error by redirecting to a default or error page
      navigate('/');
    } finally {
      setLoading(false);
    }
  };


  return (
    <div>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div>
          <h2>All Products</h2>
          <ErrorBoundary>
            <ItemGallery items={items} />
          </ErrorBoundary>
          {/* <ErrorBoundary>
            <PopularProducts items={popularItems} />
          </ErrorBoundary> */}
        </div>
      )}
    </div>
  );
};

export default AllProductsPage;