// import React from 'react';
import { Link } from 'react-router-dom';
import '../css/notfoundpage.css';
import '../App.css';


const NotFound = () => {
  return (
    <div className="error-page">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="error-wrapper">
              <div className="error-content-area text-center">
                <div className="error-animation mb-50">
                  <dotlottie-player 
                    src="https://lottie.host/dc7bf7e5-d176-4216-aed8-153b0ce0ca63/w7QY2Q2Fn3.json" 
                    background="transparent" 
                    speed="1" 
                    style={{ width: '700px', height: '5q00px' }} 
                    loop 
                    autoplay
                  ></dotlottie-player>
                </div>
                <div className="error-btn">
                  <Link to="/" className="primary-button">Back To Home</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;