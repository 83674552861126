import React from 'react';
import '../css/contactus.css';
import emailjs from 'emailjs-com';
import addressIcon from '/media/icons/location.svg';
import phoneIcon from '/media/icons/phone.svg';
import emailIcon from '/media/icons/mail.svg';

const ContactUs = () => {

  const sendEmail = (e) => {
    e.preventDefault();
    const formData = {
      from_name: e.target.name.value,
      message: e.target.message.value,
      subject: e.target.subject.value,
      email: e.target.email.value,
    };
    emailjs.send('service_jk3lzkd', 'template_lc5iq58', formData, 'SykeiB_Dr37v9UrUG')
      .then((result) => {
          console.log(result.text);
          alert('Message sent successfully!');
      }, (error) => {
          console.log(error.text);
          alert('Failed to send message. Please try again later.');
      });
    e.target.reset();
  };

    return (
      <section className="contact-us-section">
        <div className="contact-form">
        <h3 className="form-title">Get in Touch</h3> {/* Added title */}
           <form onSubmit={sendEmail}>
            <div className="form-group">
              <label htmlFor="name">Name:</label>
              <input type="text" id="name" name="name" required />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input type="email" id="email" name="email" required />
            </div>
            <div className="form-group">
              <label htmlFor="subject">Subject:</label>
              <input type="text" id="subject" name="subject" required />
            </div>
            <div className="form-group">
              <label htmlFor="message">Message:</label>
              <textarea id="message" name="message" rows="4" required></textarea>
            </div>
            <button type="submit">Submit</button>
          </form>
        </div>
        <div className="contact-details">
            <p><img src={addressIcon} alt="Address Icon" style={{ width: '20px', marginRight: '10px' }} /> Axarnon 20, Limassol 3056</p>
            <p><img src={phoneIcon} alt="Phone Icon" style={{ width: '20px', marginRight: '10px' }} /> Phone: (+357) 25394245</p>
            <p><img src={emailIcon} alt="Email Icon" style={{ width: '20px', marginRight: '10px' }} /> Email: daka.collections@gmail.com</p>
          <div className="map-container">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3168.947123456789!2d32.9750915!3d34.6687799!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14e7330f9dd6b563%3A0xb9bb51f1f0cbda65!2sDAKA%20KIDS%20YPSONAS!5e0!3m2!1sen!2s!4v1234567890!5m2!1sen!2s"
              width="100%"
              height="400"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              title="Location Map"
            ></iframe>
          </div>
        </div>
      </section>
    );
  };
  
  export default ContactUs;