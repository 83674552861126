/* eslint-disable no-unused-vars */
import React from 'react';
import Header from '../components/Header'; // Import the Header component
import Footer from '../components/Footer';
import '../css/storehome.css';
import Carousel from '../components/Carousel';
import CategoryBox from '../components/CategoryBox'; // Import the CategoryBox component
import PromotionalBanner from '../components/PromotionalBanner';

const WorkwearPage = () => {
  const images = [
    'https://via.placeholder.com/800x400/ff5733/fff',
    'https://via.placeholder.com/800x400/33ff57/fff',
    'https://via.placeholder.com/800x400/5733ff/fff',
  ];

  return (
    <div>
      {/* <Carousel images={images} /> */}
      <div className="shop-section">
        <h2 className="sub-title">COMING SOON</h2>
      </div>
    </div>
  );
};

export default WorkwearPage;