import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchItemsPerCategory } from '../utils/MainFunctionality'; 
import ItemGallery from '../components/ItemGallery';
import PopularProducts from '../components/PopularProducts'; 
import ErrorBoundary from '../utils/ErrorBoundary';

const ItemShopPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);

  // Extract id and subcategory from state or fallback to localStorage
  const id = location.state?.id || localStorage.getItem('selectedItemCategoryId');
  const subcategory = location.state?.subcategory || localStorage.getItem('selectedSubcategory');

  // Save id and subcategory to localStorage if available in location.state
  useEffect(() => {
    if (location.state?.id) {
      localStorage.setItem('selectedItemCategoryId', location.state.id);
    }
    if (location.state?.subcategory) {
      localStorage.setItem('selectedSubcategory', location.state.subcategory);
    }
  }, [location.state]);

  useEffect(() => {
    if (id) {
      fetchItems(id);
    } else {
      // Handle missing id by redirecting to a default or error page
      navigate('/');
    }
  }, [id, navigate]);

  const fetchItems = async (id) => {
    setLoading(true);
    try {
      const fetchedItems = await fetchItemsPerCategory(id);
      setItems(fetchedItems);
    } catch (error) {
      console.error('Error fetching items:', error);
    } finally {
      setLoading(false);
    }
  };

  const popularItems = items.slice(0, 4); // Select the first 4 items as popular products

  return (
    <div>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div>
          <h2>{subcategory || 'Items'}</h2> {/* Fallback to 'Items' if subcategory is not available */}
          <ErrorBoundary>
            <ItemGallery items={items} />
          </ErrorBoundary>
          <ErrorBoundary>
            <PopularProducts items={popularItems} />
          </ErrorBoundary>
        </div>
      )}
    </div>
  );
};

export default ItemShopPage;
