// import React from 'react';
import ContactUs from '../components/ContactUs';
import '../css/contactpage.css'; // Assuming you have a CSS file for styling


const ContactPage = () => {
  return (
    <div className="contact-page">
      <div className="contact-section">
        {/* <h2 className="sub-title">CONTACT US</h2> */}
        <ContactUs />
      </div>
      {/* <div className="locations-section">
        <h2 className="sub-title">LOCATIONS</h2>
        <div className="locations-gallery">
          <div className="location">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3168.947123456789!2d32.9750915!3d34.6687799!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14e7330f9dd6b563%3A0xb9bb51f1f0cbda65!2sDAKA%20KIDS%20YPSONAS!5e0!3m2!1sen!2s!4v1234567890!5m2!1sen!2s"
              width="100%"
              height="300"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              title="Ypsonas Location"
            ></iframe>
            <div className="location-title"><strong>YPSONAS</strong></div>
            <div className="location-address">Axarnon 20, Limassol 3056</div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default ContactPage;